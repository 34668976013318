//-------------------------------------------------------------
// INDEX
// ・BreakPointのレート
// ・色の定義
// ・z-indexの定義
// ・ブレークポイントの定義
// ・フォントの定義
//-------------------------------------------------------------

//色の定義
//-------------------------------------------------------------
$BREAK_POINT_MD_RATE: .7;

//色の定義
//-------------------------------------------------------------
$colors: (
  text: (
    white: #fff,
    black: #241815,
    gold: #dca138,
    red: #dc000c,
    gray:#98a6b5,
    gray-dark: #444,
    blue: #00f,
  ),
  bd: (
    white: #fff,
    black: #241815,
    gold: #dca138,
    red: #dc000c,
    pink: #ec737a,
    gray: #dfe6e9,
  ),
  bg: (
    white: #fff,
    gray: #fcfcfd,
    black: #241815,
    orange: #ffbd35,
    green: #8dc33a,
    gold: #dca138,
    red: #dc000c,
    red-dark: #9a010e,
  ),
  gra: (
    red: linear-gradient(to right, #ed0310 0%, #eb0310 37.44%, #af000a 100%),
    orange: linear-gradient(#fbe58e 0%, #e0a038 100%)
  )
);


//z-indexの定義
//-------------------------------------------------------------
$zindex: (
  ham: 205,
  front: 200,
  gnav: 90,
  modal: (
    main: 80
  ),
  banner: 10,
  main: 1,
  back: -1
);



//ブレークポイントの定義
//-------------------------------------------------------------

//メディアクエリの変数
$breakpoints: (
  xs: 414,
  sm: 720,
  md: 1030,
  lg: 1366
);

//JSにエクスポートさせるために一旦変数にする。
$breakpoint-xs: map-get($breakpoints, xs);
$breakpoint-sm: map-get($breakpoints, sm);
$breakpoint-md: map-get($breakpoints, md);
$breakpoint-lg: map-get($breakpoints, lg);
//JSにエクスポートさせる。
:export {
  lg: $breakpoint-lg;
  md: $breakpoint-md;
  sm: $breakpoint-sm;
  xs: $breakpoint-xs;
}
//xs: スマートフォン
//sm: スマートフォンとタブレットの切り替え
//md: タブレットと小さいPCの切り替え
//lg: 小さいPCと大きいPCの切り替え

//カンプのインナー横幅
$inner-width: map-get($breakpoints, md );
//スマホ時の左右のpadding
$wrapper-padding: 15;
//フォント
//-------------------------------------------------------------

//font-familyメーカー
//https://saruwakakun.com/font-family

$fonts: (
  ja: (
    sans: ('Meiryo UI',meiryo,'Noto Sans JP','Yu Gothic','MS PGothic','Segoe UI',tahoma,helvetica,sans-serif),
    serif: (serif),
  ),
  noto: (
    sans: ('Noto Sans JP', sans-serif),
  ),
  en: (
    sans: (arial,sans-serif),
    serif: (serif),
  )
);
