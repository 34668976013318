@import './src/styles/@foundation/foundation';

.voice_item {
  padding: 30px 0;
  border-top: 2px solid color(bd,red);
  &:last-of-type {
    border-bottom: 2px solid color(bd,red);
  }
  @include media(sm,max) {
    padding: 25px 0;
  }
}

.voice_title {
  margin-bottom: .5em;
  font-size: 1.8rem;
  @include font(Ja,bold);
}

.voice_text {
  line-height: (27.2/16);
}

.voice_parson {
  text-align: right;
  &--name {
    display: inline-block;
    margin-left: 1em;
  }
}

.voice_content {
  margin-bottom: 60px;
}
