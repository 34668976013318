@import './src/styles/@foundation/foundation';
.main-slider {
  position: relative;
  overflow: hidden;
  @include media(md,max) {
    @include overflow;
  }
  &::before {
    display: block;
    padding-top: percentage(540/1366);
    content: '';
    @include media(sm,max) {
      padding-top: percentage(329/415);
    }
  }
  .swiper-wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }
  &--title {
    position: absolute;
    top: 50px;
    left: 18px;
    z-index: zindex(banner);
    padding: 10px 2em;
    font-size: 2.9rem;
    background-color: rgba(color(bg,white),.85);
    box-shadow: -3px 3px rgba(color(bg,gold),.85);
    @include font(Ja,bold);
    @include media(sm,max) {
      top: auto;
      bottom: 35px;
      left: 20px;
      padding: 5px 1em;
      font-size: 1.9rem;
    }
  }
}

.home.main {
  padding-top: 0;
}

.top_lead {
  position: relative;
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: center;
  @include font(Ja,bold);
  &::before, &::after {
    position: absolute;
    bottom: 30px;
    left: 50%;
    display: block;
    width: 6px;
    height: 150px;
    content: '';
    background-color: color(bg,red);
    @include media(sm,max) {
      display: none;
    }
    @include media(md, max) {
      height: 100px;
    }
  }
  &::before {
    transform: translateX(-360px) rotate(-25deg);
    @include media(md, max) {
      transform: translateX(-330px) rotate(-25deg);
    }
  }
  &::after {
    transform: translateX(360px) rotate(25deg);
    @include media(md, max) {
      transform: translateX(330px) rotate(25deg);
    }
  }
  &--01 {
    font-size: 2.2rem;
    @include media(sm, max) {
      font-size: 2rem;
    }
  }
  &--02 {
    margin-top: 10px;
    font-size: 3.4rem;
    color: color(text,red);
    @include media(sm, max) {
      font-size: 3rem;
    }
  }
  &--03 {
    margin-top: 10px;
    font-size: 1.9rem;
  }

  .rubi-wrap {
    position: relative;
    .rubi {
      position: absolute;
      top: -5px;
      left: 50%;
      width: 100%;
      font-size: 1rem;
      text-align: center;
      white-space: nowrap;
      transform: translateX(-50%);
    }
  }
}

.top_outline {
  padding: 85px 0 45px;
  @include overflow(ture);
  background-color: color(bg,red);
  @include media(sm,max) {
    padding-top: 69px;
  }
  &--inner {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 45px 25px 30px;
    background-color: color(bg,white);
    @include media(sm,max) {
      align-items: center;
      padding: 15px;
    }
  }
  .worries-content {
    position: relative;
    padding: 70px 25px 48px;
    @include media(sm,max) {
      padding: 39px 15px 15px;
    }
  }
  &--text {
    font-size: 1.9rem;
    line-height: (28/19);
    text-align: center;
    @include font(Ja,bold);
    @include media(sm,max) {
      font-size: 1.7rem;
    }
  }
  .inner_roundtitle {
    position: absolute;
    top: -37px;
    left: 50%;
    padding: .4em 1.7em .3em;
    font-size: 3rem;
    color: color(text,red);
    text-align: center;
    background-color: color(bg,white);
    border: 1px solid color(text,red);
    border-radius: 37px;
    transform: translateX(-50%);
    @include font(Ja,bold);
    @include media(sm,max) {
      top: -24px;
      font-size: 1.9rem;
      letter-spacing: -.5px;
    }
  }
  &--lead {
    max-width: 800px;
    padding: .8em 30px;
    margin: 40px auto;
    font-size: 3rem;
    color: color(text,white);
    text-align: center;
    background: color(bg,red);
    border-radius: 72px;
    @include font(Ja,bold);
    @include media(sm,max) {
      padding: .8em 20px;
      font-size: 1.9rem;
      letter-spacing: -.5px;
      border-radius: 48px;
    }
  }
  .inner_title {
    margin-bottom: 1em;
    font-size: 3rem;
    color: color(text,red);
    text-align: center;
    @include font(Ja,bold);
    @include media(sm,max) {
      font-size: 1.9rem;
      letter-spacing: -.5px;
    }
  }
  .inner_point {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: -20px;
    @include media(sm,max) {
      max-width: 400px;
    }
    &--item {
      position: relative;
      width: calc(20% - 20px);
      margin-bottom: 10px;
      margin-left: 20px;
      background: color(gra,orange);
      border-radius: 50%;
      @include media(sm,max) {
        width: calc(50% - 20px);
      }
      &::before {
        display: block;
        padding-top: 100%;
        content: '';
      }
      > img {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
      }
      &:nth-of-type(1) {
        > img {
          width: 69%;
        }
      }
      &:nth-of-type(2) {
        > img {
          width: 76%;
        }
      }
      &:nth-of-type(3) {
        > img {
          width: 80%;
        }
      }
      &:nth-of-type(4) {
        > img {
          width: 70%;
        }
      }
      &:nth-of-type(5) {
        > img {
          width: 63%;
        }
      }
    }
  }
  .inner_note {
    position: relative;
    display: block;
    padding-left: 2em;
    margin-left: auto;
    font-size: 1rem;
    @include media(sm,max) {
      margin-right: auto;
      margin-left: 0;
      transform: translateX(-5%) translate(50vw,-150px);
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '※';
    }
  }
  .inner_img {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &--boo {
      position: relative;
      width: 100%;
      max-width: 600px;
      @include media(sm, max) {
        margin-top: 10px;
      }
    }
    &--text {
      position: absolute;
      top: 30%;
      left: 36%;
      @include font(Ja,bold);
      font-size: 1.9rem;
      @include media(sm,max) {
        left: 33%;
        font-size: 1rem;
      }
    }
    &--img {
      width: 100%;
      max-width: 350px;
      margin-left: auto;
    }
  }
}

.top_news {
  position: relative;
  padding: 40px 0 30px;
  @include media(sm,max) {
    padding: 30px 0;
  }
  &::before, &::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    content: '';
    background: repeating-linear-gradient(90deg, transparent, transparent 6px, color(bg, red) 6px, color(bg, red) 12px);
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
  &--item {
    &:not(:first-of-type) {
      margin-top: .8em;
    }
  }

  &--time {
    display: inline-block;
    margin-right: 15px;
    font-size: 1.3rem;
  }

  &--link {
    display: inline-block;
    color: color(text, black);
    @include hovertext;
  }
  &--more {
    display: block;
    width: 110px;
    margin-left: auto;
    color: color(text, black);
    @include hovertext;
    &::after {
      display: inline-block;
      width: .45em;
      height: .45em;
      margin-left: 10px;
      content: '';
      border-top: 1px solid color(text,black);
      border-right: 1px solid color(text,black);
      transform: translateY(-2px) rotate(45deg);
    }
  }
}


.top_movie {
  position: relative;
  width: 100%;
  max-width: 805px;
  margin: 0 auto;
  &::before {
    display: block;
    padding-top: percentage(9/16);
    content: '';
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
