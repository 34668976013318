@import './src/styles/@foundation/foundation';

.kenkou_lead {
  @include media(sm,max) {
    display: flex;
    flex-flow: column-reverse;
    flex-wrap: wrap;
  }
  &--info {
    @include media(sm, max) {
      width: 100%;
    }
    .name {
      text-align: right;
    }
  }
  &--img {
    float: right;
    width: 50%;
    margin-left: 20px;
    @include media(sm,max) {
      width: 100%;
      max-width: 400px;
      margin: 25px auto 0;
    }
  }
  &--text {
    margin-top: 1.5em;
    line-height: (30/16);
    em {
      @include font(Ja,bold);
    }
  }
}

.kenkou_list {
  &--item {
    position: relative;
    padding-left: 1em;
    @include font(Ja,bold);
    font-size: 1.8rem;
    &:not(:first-of-type) {
      margin-top: .7em;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '・';
    }
  }
}

.kenkou_shop {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  &--item {
    width: percentage(1/3);
    padding-left: 30px;
    @include media(sm,max) {
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
      &:not(:first-of-type) {
        margin-top: 30px;
      }
    }
  }
  &--img {
    position: relative;
    overflow: hidden;
    &::before {
      display: block;
      padding-top: percentage(256/325);
      content: '';
      @include media(sm,max) {
        padding-top: percentage(200/385);
      }
    }
    .img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: 100%;
      transform: translate(-50%,-50%);
      @include media(sm,max) {
        width: 100%;
        height: auto;
      }
    }
  }
  &--title {
    font-size: 2.1rem;
    text-align: center;
    background-color: color(bg,green);
    @include font(Ja,bold);
    @include media(sm,max) {
      font-size: 1.6rem;
      line-height: 2.5;
    }
  }
}

.kenkou_yoyaku {
  display: flex;
  flex-wrap: wrap;

  &--title {
    margin-bottom: .3em;
    font-size: 2rem;
    font-weight: 700;
  }
  &--info {
    width: calc(100% - 232px);
    padding-right: 20px;
    @include media(sm, max) {
      width: 100%;
    }
    &-inner {
      margin-top: 2.4em;
    }
  }
  &--heading {
    font-size: 2.2rem;
    line-height: 2.2rem;
    @include font(ja,bold);
  }
  &--text {
    font-size: 1.8rem;
    @include font(ja,bold);
    a {
      font-size: 3rem;
    }
    &.address {
      line-height: 2.2rem;
    }
  }
  &--post {
    font-size: 1.6rem;
  }
  &--tel {
    display: block;
    margin-top: 1em;
    margin-bottom: .4em;
    font-size: 2.8rem;
    color: color(text,black);
    @include font(Ja,bold);
  }
  &--img {
    position: relative;
    width: 100%;
    max-width: 232px;
    @include media(sm, max) {
      width: 100%;
      margin: 0 auto;
      margin-top: 2em;
    }
  }
  &--boo_text {
    position: absolute;
    top: 45%;
    left:30%;
    @include font(Ja,bold);
    font-size: 1.9rem;
    color: white;
  }
}
