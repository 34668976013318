@import './src/styles/@foundation/foundation';
.privacy {
  margin-top: 1.5em;
  @include media(sm) {
    margin-top: 2em;
  }
  &--title {
    font-size: 1.8rem;
    line-height: (30.6/18);
    @include font(Ja, bold);
  }

  &--data {
    font-size: 1.6rem;
    line-height: (30.6/16);
    @include font(Ja, Regular);
    &:not(:last-of-type) {
      margin-bottom: 1.5em;
    }
    @include media(sm) {
      &:not(:last-of-type) {
        margin-bottom: 2em;
      }
    }
  }
}
