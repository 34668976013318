@import './src/styles/@foundation/foundation';

.doctor_lead {
  margin-bottom: 75px;
  text-align: center;
  @include media(sm,max) {
    margin-bottom: 60px;
  }
  &--img {
    width: 340px;
    margin: 30px auto;
  }
  &--text {
    font-size: 3rem;
    @include font(Ja,bold);
    &:not(:first-of-type) {
      margin-top: 1.5em;
    }
    @include media(sm,max) {
      font-size: 2.4rem;
    }
    &._small {
      font-size: 2.4rem;
      @include media(sm,max) {
        font-size: 2.2rem;
      }
    }
  }
}

.doctor_sec1 {
  &--title {
    padding-left: 16px;
    margin-bottom: 1em;
    font-size: 2.6rem;
    line-height: 1.4;
    border-left: 11px solid color(bd,red);
    @include font(Ja,bold);
  }
  &--text {
    line-height: (27.2/16);
  }
  &--img {
    width: 100%;
    max-width: 868px;
    margin-top: 1em;
  }
}

.doctor_flow {
  border: 2px solid color(bd,red);
  @include font(Ja,bold);
  &._first {
    position: relative;
    margin-bottom: 35px;
    text-align: center;
    &::after {
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      display: block;
      width: 54px;
      height: 20px;
      content: '';
      border-top: 20px solid color(bd, red);
      border-right: 27px solid transparent;
      border-left: 27px solid transparent;
      transform: translateX(-50%);
    }
  }
  &--text {
    display: inline-block;
    margin: 1em;
    text-align: left;
  }
  &--list {
    padding: 50px;
    @include media(sm,max) {
      padding: 1em;
    }
  }
  &--item {
    position: relative;
    padding-left: 1em;
    &:not(:first-of-type) {
      margin-top: 1em;
      @include media(sm,max) {
        margin-top: .5em;
      }
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '･';
    }
  }
  &--note {
    padding: 1em;
    color: color(text,white);
    background-color: color(bg,red);
  }
  &--img {
    position: relative;
    width: 100%;
    max-width: 640px;
    margin: 80px auto 0;
    @include media(sm,max) {
      max-width: 380px;
      margin: 33px auto 0;
    }
    .boo_text {
      position: absolute;
      top: 43%;
      left: 4.8%;
      @include font(Ja,bold);
      font-size: 2.5rem;
      @include media(sm,max) {
        left: 4.4%;
        font-size: 1.3rem;
      }

      .note {
        display: block;
        margin-top: .2em;
        font-size: .8em;
        color: color(text, gray-dark);
        text-align: right;
      }
    }
  }
}

.doctor_faq {
  position: relative;
  &--img {
    position: absolute;
    top: 60px;
    right: 0;
    @include media(sm,max) {
      position: static;
      width: 100%;
      max-width: 380px;
      margin: 0 auto 20px;
    }
  }
  ._q1 {
    width: calc(100% - 350px);
    @include media(sm,max) {
      width: 100%;
    }
  }
  &--title {
    margin-bottom: .5em;
    font-size: 2rem;
    @include font(Ja,bold);
  }
  &--item {
    position: relative;
    padding-left: 1em;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '◎';
    }
  }
  &--data {
    &:not(:last-of-type) {
      margin-bottom: 50px;
    }
  }
}

.doctor_exp {
  &--title {
    margin-top: 35px;
    margin-bottom: .3em;
    font-size: 2.4rem;
    @include font(Ja,bold);
  }
  &--data {
    .text {
      line-height: (30/16);
      @include media(sm,max) {
        line-height: (27/16);
      }
      &:not(:first-of-type) {
        margin-top: 2em;
      }
    }
    .name {
      margin-top: 20px;
      font-size: 1.1rem;
      text-align: right;
    }
  }
}
