@import './src/styles/@foundation/foundation';

.fee_content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;

  @include media(md, max) {
    flex-direction: column-reverse;
    padding-top: 20px;
  }

  .fee {
    width: 100%;
    max-width: 480px
  }

  .note {
    margin-left: auto;

    @include media(md, max) {
      margin-bottom: 30px;
      margin-left: 0;
    }
  }
}

.fee_bnr {
  position: relative;
  margin-bottom: 110px;

  @include media(sm, max) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  &::before {
    display: block;
    padding-top: percentage(216/1032);
    content: '';

    @include media(sm, max) {
      padding-top: percentage(311/384);
    }
  }

  .img {
    @include position;
  }
}

.fee_table {
  width: 100%;
  margin-bottom: 10px;
  table-layout: fixed;

  &._member {
    max-width: 780px;
  }

  &._sub {
    width: 100%;

    @include media(sm) {
      width: 50%;
    }
  }

  &--cell {
    height: 63px;
    text-align: center;
    border-right: 2px solid color(bd, red);

    @include media(sm, max) {
      height: 50px;
    }

    &._title {
      font-size: 1.7rem;
      color: color(text, white);
      background-color: color(bg, red);
      @include font(ja, bold);
    }
  }

  thead {
    .fee_table--cell:first-of-type {
      border-left: 2px solid color(bd, red);
    }

    .fee_table--cell:not(:last-of-type) {
      border-right: 2px solid color(bd, white);
    }
  }

  tbody {
    .fee_table--cell {
      border-bottom: 2px solid color(bd, red);
      border-left: 2px solid color(bd, red);
    }

    .fee_table--cell._title {
      border-top: 2px solid color(bd, white);
      border-bottom: none;

      &._border-r {
        border-right: 2px solid color(bd, white);
      }

      &._bdb-red {
        border-bottom: 2px solid color(bd, red);
      }
    }
  }
}

.fee_note {
  &--item {
    &._forcus {
      display: inline-block;
      padding: 0 10px;
      margin-bottom: 10px;
      font-size: 2.8rem;
      color: color(text, white);
      background: color(bg, red);
      border-radius: 23px;

      &::before {
        display: none;
      }
    }

    position: relative;
    padding-left: .8em;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 1em;
      height: 1em;
      content: '・';
      // background: url(../../img/common/icon_happy.svg) no-repeat;
      // background-size: contain;
    }

    &._circle {
      position: relative;
      padding-left: 1.3em;

      &::before {
        position: absolute;
        top: 14px;
        left: 0;
        display: block;
        font-size: 1rem;
        content: '●';
        transform: translate(0, -50%);
      }

      &:last-of-type {
        font-weight: 600;
        color: color(text, blue);

        &::before {
          display: none;
        }
      }
    }

    ._red {
      color: color(text, red);
    }
  }
}

.otoku {
  position: relative;
  width: 100%;
  max-width: 570px;
  margin: 20px auto;

  .boo-text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 1.6rem;
    font-weight: 700;
    transform: translate(-80%,-50%);

    @include media(sm) {
      font-size: 1.9rem;
    }
  }
}

.product_area {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1rem;
  max-width: 790px;
  margin: 0 auto;
  @include media(sm,max){
    display: block;
    justify-items: center;
    max-width: 440px;
  }


  & .area1 {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, auto);
    row-gap: 1rem;
    column-gap: 1rem;
    @include media(sm,max){
      justify-items: center;
      padding-top: 1rem;
    }
  }

  & .area2 {
    display: grid;
    grid-template-rows: repeat(2, auto);
    row-gap: 1rem;
    column-gap: 1rem;
    justify-items: end;
    @include media(sm,max){
      justify-items: center;
      padding-top: 1rem;
    }
  }

  & .mask {
    grid-row: 1/2;
    grid-column: 1/2;
    max-width: 265px;
    &:hover {
      opacity: .5;
      transition-duration: .5s;
    }
  }
  & .rocksalt {
    grid-row: 1/2;
    grid-column: 2/3;
    max-width: 159px;
  }
  & .modelsalon {
    grid-row: 2/3;
    grid-column: 1/3;
    max-width: 406px
  }
  & .partition {
    grid-row: 1/2;
    grid-column: 1/2;
    max-width: 333px;
    &:hover {
      opacity: .5;
      transition-duration: .5s;
    }
  }
  & .doctor365 {
    grid-row: 2/3;
    grid-column: 1/2;
    max-width: 298px;
    &:hover {
      opacity: .5;
      transition-duration: .5s;
    }
  }
}