@import './src/styles/@foundation/foundation';

.sekitsui_heading {
  margin-bottom: 70px;
  font-size: 3.4rem;
  color: color(text,red);
  text-align: center;
  @include font(Ja,bold);
  @include media(sm,max) {
    margin-bottom: 35px;
    font-size: 2.1rem;
  }
}

.sekitsui_title {
  margin-bottom: .3em;
  font-size: 1.8rem;
  @include font(Ja,bold);
}
.sekitsui_text {
  line-height: (30/16);
}

.sekitsui_lead {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

.sekitsui-img {
  float: right;
  width: 55%;
  @include media(sm) {
    margin-left: 45px;
  }
  @include media(sm,max) {
    float: none;
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
  }
}

.sekitsui-sec {
  &:not(:first-of-type) {
    margin-top: 45px;
    @include media(sm,max) {
      margin-top: 30px;
    }
  }
}

.sekitsui_list {
  &--item {
    position: relative;
    padding-left: 1.2em;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '◎';
    }
  }
}
