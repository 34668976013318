@charset "UTF-8";
:export {
  lg: 1366;
  md: 1030;
  sm: 720;
  xs: 414;
}

.doctor_lead {
  margin-bottom: 75px;
  text-align: center;
}
@media (max-width: 719.98px) {
  .doctor_lead {
    margin-bottom: 60px;
  }
}
.doctor_lead--img {
  width: 340px;
  margin: 30px auto;
}
.doctor_lead--text {
  font-size: 3rem;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.doctor_lead--text:not(:first-of-type) {
  margin-top: 1.5em;
}
@media (max-width: 719.98px) {
  .doctor_lead--text {
    font-size: 2.4rem;
  }
}
.doctor_lead--text._small {
  font-size: 2.4rem;
}
@media (max-width: 719.98px) {
  .doctor_lead--text._small {
    font-size: 2.2rem;
  }
}

.doctor_sec1--title {
  padding-left: 16px;
  margin-bottom: 1em;
  font-size: 2.6rem;
  line-height: 1.4;
  border-left: 11px solid #dc000c;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.doctor_sec1--text {
  line-height: 1.7;
}
.doctor_sec1--img {
  width: 100%;
  max-width: 868px;
  margin-top: 1em;
}

.doctor_flow {
  border: 2px solid #dc000c;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.doctor_flow._first {
  position: relative;
  margin-bottom: 35px;
  text-align: center;
}
.doctor_flow._first::after {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  display: block;
  width: 54px;
  height: 20px;
  content: "";
  border-top: 20px solid #dc000c;
  border-right: 27px solid transparent;
  border-left: 27px solid transparent;
  transform: translateX(-50%);
}
.doctor_flow--text {
  display: inline-block;
  margin: 1em;
  text-align: left;
}
.doctor_flow--list {
  padding: 50px;
}
@media (max-width: 719.98px) {
  .doctor_flow--list {
    padding: 1em;
  }
}
.doctor_flow--item {
  position: relative;
  padding-left: 1em;
}
.doctor_flow--item:not(:first-of-type) {
  margin-top: 1em;
}
@media (max-width: 719.98px) {
  .doctor_flow--item:not(:first-of-type) {
    margin-top: 0.5em;
  }
}
.doctor_flow--item::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "･";
}
.doctor_flow--note {
  padding: 1em;
  color: #fff;
  background-color: #dc000c;
}
.doctor_flow--img {
  position: relative;
  width: 100%;
  max-width: 640px;
  margin: 80px auto 0;
}
@media (max-width: 719.98px) {
  .doctor_flow--img {
    max-width: 380px;
    margin: 33px auto 0;
  }
}
.doctor_flow--img .boo_text {
  position: absolute;
  top: 43%;
  left: 4.8%;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}
@media (max-width: 719.98px) {
  .doctor_flow--img .boo_text {
    left: 4.4%;
    font-size: 1.3rem;
  }
}
.doctor_flow--img .boo_text .note {
  display: block;
  margin-top: 0.2em;
  font-size: 0.8em;
  color: #444;
  text-align: right;
}

.doctor_faq {
  position: relative;
}
.doctor_faq--img {
  position: absolute;
  top: 60px;
  right: 0;
}
@media (max-width: 719.98px) {
  .doctor_faq--img {
    position: static;
    width: 100%;
    max-width: 380px;
    margin: 0 auto 20px;
  }
}
.doctor_faq ._q1 {
  width: calc(100% - 350px);
}
@media (max-width: 719.98px) {
  .doctor_faq ._q1 {
    width: 100%;
  }
}
.doctor_faq--title {
  margin-bottom: 0.5em;
  font-size: 2rem;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.doctor_faq--item {
  position: relative;
  padding-left: 1em;
}
.doctor_faq--item::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "◎";
}
.doctor_faq--data:not(:last-of-type) {
  margin-bottom: 50px;
}

.doctor_exp--title {
  margin-top: 35px;
  margin-bottom: 0.3em;
  font-size: 2.4rem;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.doctor_exp--data .text {
  line-height: 1.875;
}
@media (max-width: 719.98px) {
  .doctor_exp--data .text {
    line-height: 1.6875;
  }
}
.doctor_exp--data .text:not(:first-of-type) {
  margin-top: 2em;
}
.doctor_exp--data .name {
  margin-top: 20px;
  font-size: 1.1rem;
  text-align: right;
}

:export {
  lg: 1366;
  md: 1030;
  sm: 720;
  xs: 414;
}

.fee_content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}
@media (max-width: 1029.98px) {
  .fee_content {
    flex-direction: column-reverse;
    padding-top: 20px;
  }
}
.fee_content .fee {
  width: 100%;
  max-width: 480px;
}
.fee_content .note {
  margin-left: auto;
}
@media (max-width: 1029.98px) {
  .fee_content .note {
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.fee_bnr {
  position: relative;
  margin-bottom: 110px;
}
@media (max-width: 719.98px) {
  .fee_bnr {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
}
.fee_bnr::before {
  display: block;
  padding-top: 20.9302325581%;
  content: "";
}
@media (max-width: 719.98px) {
  .fee_bnr::before {
    padding-top: 80.9895833333%;
  }
}
.fee_bnr .img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fee_table {
  width: 100%;
  margin-bottom: 10px;
  table-layout: fixed;
}
.fee_table._member {
  max-width: 780px;
}
.fee_table._sub {
  width: 100%;
}
@media (min-width: 720px) {
  .fee_table._sub {
    width: 50%;
  }
}
.fee_table--cell {
  height: 63px;
  text-align: center;
  border-right: 2px solid #dc000c;
}
@media (max-width: 719.98px) {
  .fee_table--cell {
    height: 50px;
  }
}
.fee_table--cell._title {
  font-size: 1.7rem;
  color: #fff;
  background-color: #dc000c;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.fee_table thead .fee_table--cell:first-of-type {
  border-left: 2px solid #dc000c;
}
.fee_table thead .fee_table--cell:not(:last-of-type) {
  border-right: 2px solid #fff;
}
.fee_table tbody .fee_table--cell {
  border-bottom: 2px solid #dc000c;
  border-left: 2px solid #dc000c;
}
.fee_table tbody .fee_table--cell._title {
  border-top: 2px solid #fff;
  border-bottom: none;
}
.fee_table tbody .fee_table--cell._title._border-r {
  border-right: 2px solid #fff;
}
.fee_table tbody .fee_table--cell._title._bdb-red {
  border-bottom: 2px solid #dc000c;
}

.fee_note--item {
  position: relative;
  padding-left: 0.8em;
}
.fee_note--item._forcus {
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 10px;
  font-size: 2.8rem;
  color: #fff;
  background: #dc000c;
  border-radius: 23px;
}
.fee_note--item._forcus::before {
  display: none;
}
.fee_note--item::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 1em;
  height: 1em;
  content: "・";
}
.fee_note--item._circle {
  position: relative;
  padding-left: 1.3em;
}
.fee_note--item._circle::before {
  position: absolute;
  top: 14px;
  left: 0;
  display: block;
  font-size: 1rem;
  content: "●";
  transform: translate(0, -50%);
}
.fee_note--item._circle:last-of-type {
  font-weight: 600;
  color: #00f;
}
.fee_note--item._circle:last-of-type::before {
  display: none;
}
.fee_note--item ._red {
  color: #dc000c;
}

.otoku {
  position: relative;
  width: 100%;
  max-width: 570px;
  margin: 20px auto;
}
.otoku .boo-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.6rem;
  font-weight: 700;
  transform: translate(-80%, -50%);
}
@media (min-width: 720px) {
  .otoku .boo-text {
    font-size: 1.9rem;
  }
}

.product_area {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1rem;
  max-width: 790px;
  margin: 0 auto;
}
@media (max-width: 719.98px) {
  .product_area {
    display: block;
    justify-items: center;
    max-width: 440px;
  }
}
.product_area .area1 {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, auto);
  row-gap: 1rem;
  column-gap: 1rem;
}
@media (max-width: 719.98px) {
  .product_area .area1 {
    justify-items: center;
    padding-top: 1rem;
  }
}
.product_area .area2 {
  display: grid;
  grid-template-rows: repeat(2, auto);
  row-gap: 1rem;
  column-gap: 1rem;
  justify-items: end;
}
@media (max-width: 719.98px) {
  .product_area .area2 {
    justify-items: center;
    padding-top: 1rem;
  }
}
.product_area .mask {
  grid-row: 1/2;
  grid-column: 1/2;
  max-width: 265px;
}
.product_area .mask:hover {
  opacity: 0.5;
  transition-duration: 0.5s;
}
.product_area .rocksalt {
  grid-row: 1/2;
  grid-column: 2/3;
  max-width: 159px;
}
.product_area .modelsalon {
  grid-row: 2/3;
  grid-column: 1/3;
  max-width: 406px;
}
.product_area .partition {
  grid-row: 1/2;
  grid-column: 1/2;
  max-width: 333px;
}
.product_area .partition:hover {
  opacity: 0.5;
  transition-duration: 0.5s;
}
.product_area .doctor365 {
  grid-row: 2/3;
  grid-column: 1/2;
  max-width: 298px;
}
.product_area .doctor365:hover {
  opacity: 0.5;
  transition-duration: 0.5s;
}

:export {
  lg: 1366;
  md: 1030;
  sm: 720;
  xs: 414;
}

.list-table {
  display: flex;
  flex-wrap: wrap;
}
.list-table__title {
  width: 275px;
  padding: 30px 30px 30px 0;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
@media (max-width: 719.98px) {
  .list-table__title {
    position: relative;
    width: 100%;
    padding: 20px 0 10px;
    border-bottom: none;
  }
}
.list-table__title._w100 {
  width: 100%;
  padding: 30px 0 20px;
}
.list-table__content {
  width: calc(100% - 275px);
  padding: 30px 0;
}
@media (max-width: 719.98px) {
  .list-table__content {
    width: 100%;
    padding: 0 0 10px;
    border-top: none;
  }
}
.list-table__content._w100 {
  width: 100%;
  padding: 0 0 30px;
}
.list-table__content._radio {
  display: flex;
}
.list-table__content input[type^=text], .list-table__content input[type^=email], .list-table__content input[type^=tel] {
  display: block;
  width: 100%;
  height: 3em;
  padding: 0 0.5em;
  margin-top: -0.8em;
  font-size: 1.6rem;
  background-color: #fafafa;
  border: 1px solid #dfe6e9;
}
@media (max-width: 719.98px) {
  .list-table__content input[type^=text], .list-table__content input[type^=email], .list-table__content input[type^=tel] {
    margin-top: 0;
  }
}
.list-table__content > .input--contents {
  width: 100%;
  padding: 0.5em;
  background-color: #fcfcfd;
  border: 1px solid #dfe6e9;
}
.list-table__content .inputsample {
  display: block;
  padding-top: 0.5em;
  font-size: 0.9em;
  color: #666;
}

.mw_wp_form_confirm .label--required {
  display: none;
}
@media (max-width: 719.98px) {
  .mw_wp_form_confirm .list-table__title {
    padding: 30px 0 0;
  }
  .mw_wp_form_confirm .list-table__content {
    padding: 10px 0 30px;
  }
}

.label--required {
  display: inline-block;
  padding: 0.1em 0.5em 0;
  margin-left: auto;
  font-size: 1.4rem;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 500;
  color: #dc000c;
  transform: translateY(-2px);
}
.label--required::before {
  display: inline-block;
  margin-right: 4px;
  content: "※";
}

.mwform-radio-field.horizontal-item {
  display: block;
  margin-left: 0;
}
.mwform-radio-field label {
  cursor: pointer;
}
.mwform-radio-field input {
  position: absolute;
  opacity: 0;
}
.mwform-radio-field-text {
  position: relative;
  display: block;
  min-width: 9em;
  padding: 0 0.3em 0 1.5em;
  margin-left: 0.5em;
}
@media (max-width: 719.98px) {
  .mwform-radio-field-text {
    min-width: initial;
    padding-right: 1em;
  }
}
.mwform-radio-field-text::before, .mwform-radio-field-text::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 1em;
  height: 1em;
  margin: auto;
  content: "";
  background-color: #fcfcfd;
  border: 1px solid #dfe6e9;
  border-radius: 50%;
  transform: translateY(-2px);
}
.mwform-radio-field-text::after {
  background-color: #dc000c;
  transition: 0.2s transform;
  transform: translateY(-2px) scale(0);
}
:hover + .mwform-radio-field-text::after {
  opacity: 0.4;
  transform: translateY(-2px) scale(0.4);
}
:checked + .mwform-radio-field-text::after {
  opacity: 1;
  transform: translateY(-2px) scale(1);
}

.input-submit-wrap {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
@media (max-width: 719.98px) {
  .input-submit-wrap {
    margin: 30px auto 0;
  }
}

.input-submit {
  display: block;
  width: 100%;
  height: 100%;
  padding: 7px 1em;
  color: #fff;
  cursor: pointer;
  background-color: #dc000c;
  border-radius: 4px;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
@media (hover: hover) {
  .input-submit:hover {
    opacity: 0.7;
    transition: opacity 0.4s;
  }
}
body.ie.windows .input-submit:hover {
  opacity: 0.7;
  transition: opacity 0.4s;
}

_:-ms-fullscreen:hover, :root .input-submit:hover {
  opacity: 0.7;
  transition: opacity 0.4s;
}

.input-back-wrap {
  width: 100%;
  max-width: 150px;
  margin: 30px auto 0;
}
@media (max-width: 719.98px) {
  .input-back-wrap {
    margin: 30px auto 0;
  }
}

.input-back {
  display: block;
  width: 100%;
  height: 100%;
  padding: 7px 1em;
  color: #fff;
  cursor: pointer;
  background-color: #aaa;
  border-radius: 4px;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
@media (hover: hover) {
  .input-back:hover {
    opacity: 0.7;
    transition: opacity 0.4s;
  }
}
body.ie.windows .input-back:hover {
  opacity: 0.7;
  transition: opacity 0.4s;
}

_:-ms-fullscreen:hover, :root .input-back:hover {
  opacity: 0.7;
  transition: opacity 0.4s;
}

.form-thanks {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.form-thanks__title {
  margin-bottom: 20px;
  font-size: 3rem;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.form-thanks__subtitle {
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}

:export {
  lg: 1366;
  md: 1030;
  sm: 720;
  xs: 414;
}

@media (max-width: 719.98px) {
  .kenkou_lead {
    display: flex;
    flex-flow: column-reverse;
    flex-wrap: wrap;
  }
}
@media (max-width: 719.98px) {
  .kenkou_lead--info {
    width: 100%;
  }
}
.kenkou_lead--info .name {
  text-align: right;
}
.kenkou_lead--img {
  float: right;
  width: 50%;
  margin-left: 20px;
}
@media (max-width: 719.98px) {
  .kenkou_lead--img {
    width: 100%;
    max-width: 400px;
    margin: 25px auto 0;
  }
}
.kenkou_lead--text {
  margin-top: 1.5em;
  line-height: 1.875;
}
.kenkou_lead--text em {
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}

.kenkou_list--item {
  position: relative;
  padding-left: 1em;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
}
.kenkou_list--item:not(:first-of-type) {
  margin-top: 0.7em;
}
.kenkou_list--item::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "・";
}

.kenkou_shop {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
}
.kenkou_shop--item {
  width: 33.3333333333%;
  padding-left: 30px;
}
@media (max-width: 719.98px) {
  .kenkou_shop--item {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  }
  .kenkou_shop--item:not(:first-of-type) {
    margin-top: 30px;
  }
}
.kenkou_shop--img {
  position: relative;
  overflow: hidden;
}
.kenkou_shop--img::before {
  display: block;
  padding-top: 78.7692307692%;
  content: "";
}
@media (max-width: 719.98px) {
  .kenkou_shop--img::before {
    padding-top: 51.9480519481%;
  }
}
.kenkou_shop--img .img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);
}
@media (max-width: 719.98px) {
  .kenkou_shop--img .img {
    width: 100%;
    height: auto;
  }
}
.kenkou_shop--title {
  font-size: 2.1rem;
  text-align: center;
  background-color: #8dc33a;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
@media (max-width: 719.98px) {
  .kenkou_shop--title {
    font-size: 1.6rem;
    line-height: 2.5;
  }
}

.kenkou_yoyaku {
  display: flex;
  flex-wrap: wrap;
}
.kenkou_yoyaku--title {
  margin-bottom: 0.3em;
  font-size: 2rem;
  font-weight: 700;
}
.kenkou_yoyaku--info {
  width: calc(100% - 232px);
  padding-right: 20px;
}
@media (max-width: 719.98px) {
  .kenkou_yoyaku--info {
    width: 100%;
  }
}
.kenkou_yoyaku--info-inner {
  margin-top: 2.4em;
}
.kenkou_yoyaku--heading {
  font-size: 2.2rem;
  line-height: 2.2rem;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.kenkou_yoyaku--text {
  font-size: 1.8rem;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.kenkou_yoyaku--text a {
  font-size: 3rem;
}
.kenkou_yoyaku--text.address {
  line-height: 2.2rem;
}
.kenkou_yoyaku--post {
  font-size: 1.6rem;
}
.kenkou_yoyaku--tel {
  display: block;
  margin-top: 1em;
  margin-bottom: 0.4em;
  font-size: 2.8rem;
  color: #241815;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.kenkou_yoyaku--img {
  position: relative;
  width: 100%;
  max-width: 232px;
}
@media (max-width: 719.98px) {
  .kenkou_yoyaku--img {
    width: 100%;
    margin: 0 auto;
    margin-top: 2em;
  }
}
.kenkou_yoyaku--boo_text {
  position: absolute;
  top: 45%;
  left: 30%;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.9rem;
  color: white;
}

:export {
  lg: 1366;
  md: 1030;
  sm: 720;
  xs: 414;
}

.news {
  padding-bottom: calc(45px - 1em);
}
.news + .news {
  padding-top: 50px;
  border-top: 1px solid #dc000c;
}
.news img {
  width: auto;
}

.post-permalink {
  transition: opacity 0.5s;
}
@media (hover: hover) {
  .post-permalink:hover {
    opacity: 0.7;
  }
}
body.ie.windows .post-permalink:hover {
  opacity: 0.7;
}

_:-ms-fullscreen:hover, :root .post-permalink:hover {
  opacity: 0.7;
}

.news_meta {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.news_date {
  display: block;
  margin-bottom: 0.5em;
  color: #98a6b5;
}
.news_date::before {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 8px;
  content: "";
  background: url(../../img/news/icon_time.png) no-repeat;
  background-size: contain;
  transform: translateY(2px);
}

:export {
  lg: 1366;
  md: 1030;
  sm: 720;
  xs: 414;
}

.privacy {
  margin-top: 1.5em;
}
@media (min-width: 720px) {
  .privacy {
    margin-top: 2em;
  }
}
.privacy--title {
  font-size: 1.8rem;
  line-height: 1.7;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.privacy--data {
  font-size: 1.6rem;
  line-height: 1.9125;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 400;
}
.privacy--data:not(:last-of-type) {
  margin-bottom: 1.5em;
}
@media (min-width: 720px) {
  .privacy--data:not(:last-of-type) {
    margin-bottom: 2em;
  }
}

:export {
  lg: 1366;
  md: 1030;
  sm: 720;
  xs: 414;
}

.sekitsui_heading {
  margin-bottom: 70px;
  font-size: 3.4rem;
  color: #dc000c;
  text-align: center;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
@media (max-width: 719.98px) {
  .sekitsui_heading {
    margin-bottom: 35px;
    font-size: 2.1rem;
  }
}

.sekitsui_title {
  margin-bottom: 0.3em;
  font-size: 1.8rem;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}

.sekitsui_text {
  line-height: 1.875;
}

.sekitsui_lead::after {
  display: block;
  clear: both;
  content: "";
}

.sekitsui-img {
  float: right;
  width: 55%;
}
@media (min-width: 720px) {
  .sekitsui-img {
    margin-left: 45px;
  }
}
@media (max-width: 719.98px) {
  .sekitsui-img {
    float: none;
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
  }
}

.sekitsui-sec:not(:first-of-type) {
  margin-top: 45px;
}
@media (max-width: 719.98px) {
  .sekitsui-sec:not(:first-of-type) {
    margin-top: 30px;
  }
}

.sekitsui_list--item {
  position: relative;
  padding-left: 1.2em;
}
.sekitsui_list--item::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "◎";
}

:export {
  lg: 1366;
  md: 1030;
  sm: 720;
  xs: 414;
}

.main-slider {
  position: relative;
  overflow: hidden;
}
@media (max-width: 1029.98px) {
  .main-slider {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
}
.main-slider::before {
  display: block;
  padding-top: 39.5314787701%;
  content: "";
}
@media (max-width: 719.98px) {
  .main-slider::before {
    padding-top: 79.2771084337%;
  }
}
.main-slider .swiper-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}
.main-slider--title {
  position: absolute;
  top: 50px;
  left: 18px;
  z-index: 10;
  padding: 10px 2em;
  font-size: 2.9rem;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: -3px 3px rgba(220, 161, 56, 0.85);
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
@media (max-width: 719.98px) {
  .main-slider--title {
    top: auto;
    bottom: 35px;
    left: 20px;
    padding: 5px 1em;
    font-size: 1.9rem;
  }
}

.home.main {
  padding-top: 0;
}

.top_lead {
  position: relative;
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: center;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
.top_lead::before, .top_lead::after {
  position: absolute;
  bottom: 30px;
  left: 50%;
  display: block;
  width: 6px;
  height: 150px;
  content: "";
  background-color: #dc000c;
}
@media (max-width: 719.98px) {
  .top_lead::before, .top_lead::after {
    display: none;
  }
}
@media (max-width: 1029.98px) {
  .top_lead::before, .top_lead::after {
    height: 100px;
  }
}
.top_lead::before {
  transform: translateX(-360px) rotate(-25deg);
}
@media (max-width: 1029.98px) {
  .top_lead::before {
    transform: translateX(-330px) rotate(-25deg);
  }
}
.top_lead::after {
  transform: translateX(360px) rotate(25deg);
}
@media (max-width: 1029.98px) {
  .top_lead::after {
    transform: translateX(330px) rotate(25deg);
  }
}
.top_lead--01 {
  font-size: 2.2rem;
}
@media (max-width: 719.98px) {
  .top_lead--01 {
    font-size: 2rem;
  }
}
.top_lead--02 {
  margin-top: 10px;
  font-size: 3.4rem;
  color: #dc000c;
}
@media (max-width: 719.98px) {
  .top_lead--02 {
    font-size: 3rem;
  }
}
.top_lead--03 {
  margin-top: 10px;
  font-size: 1.9rem;
}
.top_lead .rubi-wrap {
  position: relative;
}
.top_lead .rubi-wrap .rubi {
  position: absolute;
  top: -5px;
  left: 50%;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
  transform: translateX(-50%);
}

.top_outline {
  padding: 85px 0 45px;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding-left: calc(50vw - 50%);
  padding-right: calc(50vw - 50%);
  background-color: #dc000c;
}
@media (max-width: 719.98px) {
  .top_outline {
    padding-top: 69px;
  }
}
.top_outline--inner {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 45px 25px 30px;
  background-color: #fff;
}
@media (max-width: 719.98px) {
  .top_outline--inner {
    align-items: center;
    padding: 15px;
  }
}
.top_outline .worries-content {
  position: relative;
  padding: 70px 25px 48px;
}
@media (max-width: 719.98px) {
  .top_outline .worries-content {
    padding: 39px 15px 15px;
  }
}
.top_outline--text {
  font-size: 1.9rem;
  line-height: 1.4736842105;
  text-align: center;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
@media (max-width: 719.98px) {
  .top_outline--text {
    font-size: 1.7rem;
  }
}
.top_outline .inner_roundtitle {
  position: absolute;
  top: -37px;
  left: 50%;
  padding: 0.4em 1.7em 0.3em;
  font-size: 3rem;
  color: #dc000c;
  text-align: center;
  background-color: #fff;
  border: 1px solid #dc000c;
  border-radius: 37px;
  transform: translateX(-50%);
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
@media (max-width: 719.98px) {
  .top_outline .inner_roundtitle {
    top: -24px;
    font-size: 1.9rem;
    letter-spacing: -0.5px;
  }
}
.top_outline--lead {
  max-width: 800px;
  padding: 0.8em 30px;
  margin: 40px auto;
  font-size: 3rem;
  color: #fff;
  text-align: center;
  background: #dc000c;
  border-radius: 72px;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
@media (max-width: 719.98px) {
  .top_outline--lead {
    padding: 0.8em 20px;
    font-size: 1.9rem;
    letter-spacing: -0.5px;
    border-radius: 48px;
  }
}
.top_outline .inner_title {
  margin-bottom: 1em;
  font-size: 3rem;
  color: #dc000c;
  text-align: center;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}
@media (max-width: 719.98px) {
  .top_outline .inner_title {
    font-size: 1.9rem;
    letter-spacing: -0.5px;
  }
}
.top_outline .inner_point {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -20px;
}
@media (max-width: 719.98px) {
  .top_outline .inner_point {
    max-width: 400px;
  }
}
.top_outline .inner_point--item {
  position: relative;
  width: calc(20% - 20px);
  margin-bottom: 10px;
  margin-left: 20px;
  background: linear-gradient(#fbe58e 0%, #e0a038 100%);
  border-radius: 50%;
}
@media (max-width: 719.98px) {
  .top_outline .inner_point--item {
    width: calc(50% - 20px);
  }
}
.top_outline .inner_point--item::before {
  display: block;
  padding-top: 100%;
  content: "";
}
.top_outline .inner_point--item > img {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}
.top_outline .inner_point--item:nth-of-type(1) > img {
  width: 69%;
}
.top_outline .inner_point--item:nth-of-type(2) > img {
  width: 76%;
}
.top_outline .inner_point--item:nth-of-type(3) > img {
  width: 80%;
}
.top_outline .inner_point--item:nth-of-type(4) > img {
  width: 70%;
}
.top_outline .inner_point--item:nth-of-type(5) > img {
  width: 63%;
}
.top_outline .inner_note {
  position: relative;
  display: block;
  padding-left: 2em;
  margin-left: auto;
  font-size: 1rem;
}
@media (max-width: 719.98px) {
  .top_outline .inner_note {
    margin-right: auto;
    margin-left: 0;
    transform: translateX(-5%) translate(50vw, -150px);
  }
}
.top_outline .inner_note::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "※";
}
.top_outline .inner_img {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top_outline .inner_img--boo {
  position: relative;
  width: 100%;
  max-width: 600px;
}
@media (max-width: 719.98px) {
  .top_outline .inner_img--boo {
    margin-top: 10px;
  }
}
.top_outline .inner_img--text {
  position: absolute;
  top: 30%;
  left: 36%;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.9rem;
}
@media (max-width: 719.98px) {
  .top_outline .inner_img--text {
    left: 33%;
    font-size: 1rem;
  }
}
.top_outline .inner_img--img {
  width: 100%;
  max-width: 350px;
  margin-left: auto;
}

.top_news {
  position: relative;
  padding: 40px 0 30px;
}
@media (max-width: 719.98px) {
  .top_news {
    padding: 30px 0;
  }
}
.top_news::before, .top_news::after {
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  content: "";
  background: repeating-linear-gradient(90deg, transparent, transparent 6px, #dc000c 6px, #dc000c 12px);
}
.top_news::before {
  top: 0;
}
.top_news::after {
  bottom: 0;
}
.top_news--item:not(:first-of-type) {
  margin-top: 0.8em;
}
.top_news--time {
  display: inline-block;
  margin-right: 15px;
  font-size: 1.3rem;
}
.top_news--link {
  display: inline-block;
  color: #241815;
  transition: opacity 0.5s;
}
@media (hover: hover) {
  .top_news--link:hover {
    opacity: 0.7;
  }
}
body.ie.windows .top_news--link:hover {
  opacity: 0.7;
}

_:-ms-fullscreen:hover, :root .top_news--link:hover {
  opacity: 0.7;
}

.top_news--more {
  display: block;
  width: 110px;
  margin-left: auto;
  color: #241815;
  transition: opacity 0.5s;
}
@media (hover: hover) {
  .top_news--more:hover {
    opacity: 0.7;
  }
}
body.ie.windows .top_news--more:hover {
  opacity: 0.7;
}

_:-ms-fullscreen:hover, :root .top_news--more:hover {
  opacity: 0.7;
}

.top_news--more::after {
  display: inline-block;
  width: 0.45em;
  height: 0.45em;
  margin-left: 10px;
  content: "";
  border-top: 1px solid #241815;
  border-right: 1px solid #241815;
  transform: translateY(-2px) rotate(45deg);
}

.top_movie {
  position: relative;
  width: 100%;
  max-width: 805px;
  margin: 0 auto;
}
.top_movie::before {
  display: block;
  padding-top: 56.25%;
  content: "";
}
.top_movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

:export {
  lg: 1366;
  md: 1030;
  sm: 720;
  xs: 414;
}

.voice_item {
  padding: 30px 0;
  border-top: 2px solid #dc000c;
}
.voice_item:last-of-type {
  border-bottom: 2px solid #dc000c;
}
@media (max-width: 719.98px) {
  .voice_item {
    padding: 25px 0;
  }
}

.voice_title {
  margin-bottom: 0.5em;
  font-size: 1.8rem;
  font-family: "Meiryo UI", meiryo, "Noto Sans JP", "Yu Gothic", "MS PGothic", "Segoe UI", tahoma, helvetica, sans-serif;
  font-weight: 700;
}

.voice_text {
  line-height: 1.7;
}

.voice_parson {
  text-align: right;
}
.voice_parson--name {
  display: inline-block;
  margin-left: 1em;
}

.voice_content {
  margin-bottom: 60px;
}