@import './src/styles/@foundation/foundation';

.news {
  padding-bottom: calc(45px - 1em);
  + .news {
    padding-top: 50px;
    border-top: 1px solid color(bd,red);
  }

  img {
    width: auto;
  }
}

.post-permalink {
  @include hovertext;
}

.news_meta {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.news_date {
  display: block;
  margin-bottom: .5em;
  color: color(text,gray);
  &::before {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: 8px;
    content: '';
    background: url(../../img/news/icon_time.png) no-repeat;
    background-size: contain;
    transform: translateY(2px);
  }
}
