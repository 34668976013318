@import './src/styles/@foundation/foundation';

.list-table {
  $title-width: 275px;
  display: flex;
  flex-wrap: wrap;

  &__title {
    width: $title-width;
    padding: 30px 30px 30px 0;
    @include font(Ja,bold);

    @include media(sm,max) {
      position: relative;
      width: 100%;
      padding: 20px 0 10px;
      border-bottom: none;
    }

    &._w100 {
      width: 100%;
      padding: 30px 0 20px;
    }
  }

  &__content {
    width: calc(100% - #{$title-width});
    padding: 30px 0;

    @include media(sm,max) {
      width: 100%;
      padding: 0 0 10px;
      border-top: none;
    }

    &._w100 {
      width: 100%;
      padding: 0 0 30px;
    }

    &._radio {
      display: flex;
    }

    input[type^='text'], input[type^='email'], input[type^='tel'] {
      display: block;
      width: 100%;
      height: 3em;
      padding: 0 .5em;
      margin-top: -.8em;
      font-size: 1.6rem;
      background-color: #fafafa;
      border: 1px solid color(bd,gray);

      @include media(sm,max) {
        margin-top: 0;
      }
    }

    > .input--contents {
      width: 100%;
      padding: .5em;
      background-color: color(bg, gray);
      border: 1px solid color(bd,gray);
    }

    .inputsample {
      display: block;
      padding-top: .5em;
      font-size: .9em;
      color: #666;
    }
  }
}

.mw_wp_form_confirm {

  .label--required {
    display: none;
  }

  @include media(sm,max) {
    .list-table__title {
      padding: 30px 0 0;
    }

    .list-table__content {
      padding: 10px 0 30px;
    }
  }
}

//任意ボタン
.label--required {
  display: inline-block;
  padding: .1em .5em 0;
  margin-left: auto;
  font-size: 1.4rem;
  @include font(Ja,medium);
  color: color(text,red);
  transform: translateY(-2px);
  &::before {
    display: inline-block;
    margin-right: 4px;
    content: '※';
  }
}


// ラジオボタン
.mwform-radio-field {
  &.horizontal-item {
    display: block;
    margin-left: 0;
  }

  label {
    cursor: pointer;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  &-text {
    position: relative;
    display: block;
    min-width: 9em;
    padding: 0 .3em 0 1.5em;
    margin-left: .5em;
    @include media(sm,max) {
      min-width: initial;
      padding-right: 1em;
    }

    &::before, &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 1em;
      height: 1em;
      margin: auto;
      content: '';
      background-color: color(bg, gray);
      border: 1px solid color(bd, gray);
      border-radius: 50%;
      transform: translateY(-2px);
    }

    &::after {
      background-color: color(bg,red);
      transition: .2s transform;
      transform: translateY(-2px) scale(0);
    }

    :hover + &::after {
      opacity: .4;
      transform: translateY(-2px) scale(.4);
    }

    :checked + &::after {
      opacity: 1;
      transform: translateY(-2px) scale(1);
    }
  }
}

.input-submit-wrap {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  @include media(sm,max) {
    margin: 30px auto 0;
  }
}

.input-submit {
  display: block;
  width: 100%;
  height: 100%;
  padding: 7px 1em;
  color: color(text,white);
  cursor: pointer;
  background-color: color(bg,red);
  border-radius: 4px;
  @include font(Ja,bold);
  @include hover {
    opacity: .7;
    transition: opacity .4s;
  }
}

.input-back-wrap {
  width: 100%;
  max-width: 150px;
  margin: 30px auto 0;
  @include media(sm,max) {
    margin: 30px auto 0;
  }
}

.input-back {
  display: block;
  width: 100%;
  height: 100%;
  padding: 7px 1em;
  color: color(text,white);
  cursor: pointer;
  background-color: #aaa;
  border-radius: 4px;
  @include font(Ja,bold);
  @include hover {
    opacity: .7;
    transition: opacity .4s;
  }
}

//送信完了ページ
.form-thanks {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  &__title {
    margin-bottom: 20px;
    font-size: 3rem;
    @include font(Ja,bold);
  }

  &__subtitle {
    margin-bottom: 15px;
    font-size: 1.8rem;
    @include font(Ja,bold);
  }
}
